.pub-name {
    font-weight: 500;
    font-size: 22px;
    text-align: center;
}

.pub-aff {
    font-weight: 500;
}

.res-info-style {
    font-size: .875rem;
}

.citation-w {
    width: 60%;
}

.h-index-w {
    width: 68%;
}

.btn-follow,
.btn-follow:focus,
.btn-follow:hover,
.btn-follow:active {
    height: 100%;
    color: #FFFFFF;
    background-color: #5644de !important;
    border-color: #5644de !important;
}

.btn-isfollow,
.btn-isfollow:focus,
.btn-isfollow:hover,
.btn-isfollow:active {
    height: 100%;
    background-color: #C1C1C1 !important;
    border-color: #C1C1C1 !important;
}

.pub-profile-content {
    margin-top: 78px;
    margin-bottom: 30px;
    min-height: 84vh;
}

.profile-col {
    width: 26%;
}

.pub-profile-content .separator {
    width: 1px;
    height: 50%;
    background: #CACED3;
}

.pub-profile-content-p {
    margin-left: 145px;
    width: 88%;
    min-height: 84vh;
}

.pub-profile-content .img-container {
    width: 100%;
    height: auto;
}

.pub-profile-content .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-img-container {
    margin-top: -50px;
}

.pub-profile-content .profile-pub-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.now-plan {
    border-radius: 14px;
    border: 1px solid #E2E8F0;
    background: #F8F9FA;
    max-width: 250px;
}

.now-plan p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.now-plan span {
    color: #999;
    text-align: center !important;
    font-size: 14px;
    font-weight: 500;
}

@media(max-width:992px) {
    .profile-col {
        width: 100%;
    }
}

.follow-style {
    font-size: 14px;
    color: #999999;
}

.follow-num-style {
    font-size: 14px;
    color: #000000 !important;
    font-weight: 600;
}

.pub-tabs .nav-tabs .nav-link.active {
    color: #5644de !important;
    background-color: none !important;
    border-color: none !important;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid #5644de !important;
    box-sizing: none !important;
}

.pub-tabs .nav-tabs .nav-link:hover,
.pub-tabs .nav-tabs .nav-link:focus,
.pub-tabs .nav-tabs .nav-link:focus-within {
    background-color: none !important;
    border-color: none !important;
    border-top: none;
    box-sizing: none !important;
    border-left: none;
    border-right: none;
    outline: none !important;
}

.pub-tabs .tab-link>.badge {
    background-color: #5644de !important;
}

.pub-tabs .tab-link {
    color: #5644de !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.tab-navbar {
    overflow-x: auto;
}

.tab-navbar .tab-item .tab-item-link {
    padding: 0rem 1rem;
}

.private-alert {
    background-color: #F9E154;
    border-radius: 7px;

    font-size: 14px;
    font-weight: 600;
}