.poster {
  margin-top: 20px;
  position: relative;
}

.poster>img,
.poster-img {
  max-width: 100%;
  object-fit: cover;
}

.v_len {
  bottom: 10px;
  right: 8px;
  background: rgba(0, 0, 0, 0.41);
  color: #FFFFFF;
  font-size: 10px;
  border-radius: 5px;
}

.modalwdth {
  width: 90vw;
  /* Occupy the 90% of the screen width */
  max-width: 90vw;
}

.poster>div {
  position: relative;
}

.pos {
  position: absolute;
}

.styling {
  top: 0;
  bottom: 0;
  height: 45px;
  width: 70px;
  border: none !important;
  background-color: transparent !important;
}

.styling:hover,
.styling:active {
  border: none;
}

.iconStlye {
  font-size: 40px;
  background-color: #FFFFFF;
  border-radius: 50%;
  width: 50%;
  height: 75%;
}

.poster-empty {
  background-color: #f4f5f7;
  width: 80px;
  height: 50px;
}

/* @media (min-width: 768px) { ... } */

@media (max-width: 992px) {
  .styling {
    height: 25px !important;
    width: 50px !important;
  }

  .iconStlye {
    font-size: 10px !important
  }
}

@media (max-width: 1200px) {
  .styling {
    height: 45px;
    width: 70px;
  }

  .iconStlye {
    font-size: 28px
  }
}

/* @media (max-width:1700px) {

  .poster>img,
  .poster-img {
    
    width: 165px;
    height: auto;    
  }

  .v_len {
    right: 5%;
  }

} */