.material table {
    border: 1px solid #e4e6ee;
    border-radius: 5px;
    border-collapse: separate !important;
    min-height: 420px;
    display: inline-block;
}


.material td {
    border: 1px solid #e4e6ee;
    border-radius: 5px;
    padding: 10px;
}

.material th {
    color: #5644de;
    border: 1px solid #e4e6ee;
    border-radius: 5px;
    padding: 10px;
}

.material td>button {
    background: #e4e6ee;
    color: inherit;
    border: 1px solid #e4e6ee;
    border-radius: 5px !important;
    padding: 3px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
    margin-bottom: 3px;
}

.material input {
    border: 2px solid #e4e6ee;
    border-radius: 5px !important;
}