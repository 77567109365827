.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
}

.auth-form-container {
  width: 75%;
}

@media(max-width:768px) {
  .auth-form-container {
    width: 100%;
  }
}

.stepwizard-step {
  background-color: #CACED3;
  width: 120px;
  height: 4px;
  border-radius: 20px;
}

.step-active {
  background-color: #5644de !important;
}

.btn-circle {
  width: 30px !important;
  height: 30px !important;
  text-align: center !important;
  padding: 6px 0 !important;
  font-size: 12px !important;
  line-height: 1.428571429 !important;
  border-radius: 15px !important;
}

.auth-form-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: #666F79;
}

.auth-form-title {
  font-weight: 700;
  font-size: 26px;
  /* line-height: 44px; */
}

.auth-logo {
  width: 35%;
}

.logo-t-style {
  color: #5644de !important;
  font-weight: 700;
  font-size: medium !important;
  text-decoration: none;
}

.auth-form-details {
  /* margin-top: 50px; */
  padding: 0px;
  gap: 48px;
}

.form-text-have-account {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #3D4956;
}

a#haveAccount {
  color: #5644de !important;
  text-decoration: none;
  font-weight: bold;
}

.form-link-forgot-password {
  text-align: right;
}

.form-link-forgot-password a {
  font-size: 14px;
  color: #5644de !important;
  text-decoration: none;
  font-weight: 700;
}

.password-icon-span:hover {
  cursor: pointer;
}

.password-icon-span {
  position: absolute;
  bottom: 10px;
  display: flex;
  font-size: 20px;
  right: 10px;
}

.auth-form-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 966px;
}

.auth-btns {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
}

.error-msg {
  color: red;
  font-weight: 600;
  text-align: center !important;
  /* width: 50%; */
}

.margin_btn {
  margin-top: 13%;
}

.select-box {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 16px !important;
  gap: 10px;
  background: transparent;
  border-radius: 8px !important;
  color: #666F79 !important;
  margin-bottom: 20px !important;
  z-index: 1;
  position: relative;
}


.interests-search {
  /* margin: auto; */
  width: 100%;
  border: solid 2px gainsboro;
  border-radius: 10px;
  padding-inline: 20px;
  padding-block: 20px;
  /* display: flex; */
  flex-direction: column;
  gap: 10px;
  z-index: 11111;
  background: #fff;
  position: absolute;
  max-height: 160px;
  overflow-y: auto;
}

.custom-badge-primary.bg-primary {
  background-color: rgba(86, 68, 222, 1) !important;
}

.user-result:hover {
  cursor: pointer;
}

.selected-interest {
  color: #5644de;
}

.research-interest-input:focus,
.research-interest-input:active {
  box-shadow: none !important;
  outline: none !important;
  border-radius: 0;
}

.research-interest-input {
  display: inline-block;
}