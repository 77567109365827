#resetformContainer span[name="Password-icon"] {
    bottom: 242px !important;
}

#resetformContainer span[name="ConfirmPassword-icon"] {
    bottom: 101px !important;
}

#resetformContainer .error-msg {
    text-align: start !important;
}