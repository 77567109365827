.from-style {
  margin-bottom: 15px;
  margin-top: 10px;
}

.from-style>input {
  padding: 10px 16px;
  margin: 0px;
  font-size: 13px;
  background: transparent !important;
}

.from-style>input::placeholder {
  color: #CACED3;
}

.from-style>input:focus {
  box-shadow: none;
}

.input-label {
  font-weight: 500;
  font-size: 14px;
  /* line-height: 24px; */
  color: #000000;
}

.from-style.required>.label-div>.input-label:after {
  content: "*";
  color: red;
}

.from-style>.input-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 10px;
  background: #F1F5F9;
  border-radius: 8px;
  color: #666F79;
}

.error {
  color: red !important;
  border: 1px red solid !important;
}

.error-msg {
  color: red;
  font-size: 15px !important;
  font-weight: bold !important;
  text-align: end !important;
  display: block;
}

.error-input>.label-div>.input-label {
  width: 49% !important;
}