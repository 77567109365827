@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-style {
    width: 350px;
    height: 350px;
    left: 35% !important;
    margin-left: -1em !important;
    position: absolute;
    margin-top: 10%;
    border-radius: 50%;
    opacity: 1 !important;
}

.loading-spinner>img {
    width: 30vw;
    /* height: 350px; */
}

.loading-spinner {
    /* width: 50px;
    height: 50px; */
    /* width: 350px;
    height: 350px; */

    /* background-image: url('/public/assets/loading.gif'); */
    /* background-size: contain; */
    /* background-repeat: no-repeat; */

    /* border: 10px solid #f3f3f3; */
    /* Light grey */
    /* border-top: 10px solid #5644de; */
    /* Black */
    border-radius: 50%;
    /* animation: spinner 1.5s linear infinite; */
    /* left: 35% !important; */
    /* margin-left: -1em !important; */
    z-index: 10000000 !important;
    /* position: fixed; */
    /* margin-top: 17%; */
    opacity: 1 !important;
}

.spinner-container {
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
}

/* 
.spinner-container {
    position: fixed;
    text-align: center;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    background-color: white;
    opacity: 0.7;
    z-index: 1000000;
} */