.tab-navbar {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #E2E8F0;
}

.tab-navbar .active {
    border-bottom: 1px solid #000;
}

.tab-navbar .active .tab-item-link {
    color: #000 !important;
}

.tab-navbar .tab-item {
    padding: 0.5rem 0rem;
    margin-inline-end: 1rem;
}

.tab-navbar .tab-item .tab-item-link {
    color: #CACED3;
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    padding: 1rem 1rem;
}