.finger-left-style {
    width: 3% !important;
    position: fixed;
    top: 25vh;
    left: 11px;
    z-index: 999 !important;
    /* border: solid 1px #f5f5f7; */
    /* background-color: #f5f5f7; */
    /* border-radius: 50px; */
    /* padding: 8px; */
}

.menu-item-style {
    color: #f5f5f7 !important;
    background-color: #FFFFFF !important;
    border: none !important;
    margin: 0 !important;
    /* padding: 0 !important; */
}

.menu-item-style-active {
    background-color: #B9AFF7 !important;
    width: auto;
    height: auto;
    /* height: 20px; */
    display: flex !important;
    justify-content: flex-start;
    padding: 8px !important;
    border-radius: 20px 50px 50px 20px !important;
    z-index: 999 !important;
    position: relative;
    border: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 2px !important;
    color: #5644de !important;

}

.menu-item-style-clicked {
    color: #f5f5f7 !important;
    background-color: #B9AFF7 !important;
    border: none !important;
    margin: 0 !important;
}

.menu-item-style-active span {
    color: #5644de;
    font-size: 14px;
    font-weight: 700;
}

.finger-left-style .tooltip-style>.tooltip-inner {
    background-color: #B9AFF7 !important;
    color: #000000 !important;
}

.finger-left-style .tooltip-style>.tooltip-arrow::before {
    border-right-color: #B9AFF7 !important;
    /* display: none !important; */
}