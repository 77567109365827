.PlayVideosContainer .loading-spinner {
    margin-top: 7%;
}

.info_alert {
    position: fixed !important;
    bottom: 0;
    left: 10px;
    padding-top: 12px !important;
    padding-bottom: 9px !important;
    z-index: 99999;
    font-size: 12px !important;
}