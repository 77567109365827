.notifications .dropdown-toggle::after {
    display: none;
}

.unread {
    background-color: #e9edf2 !important;
}

.notifications .dropdown-menu.show {
    max-height: 69vh !important;
    width: 240px;
    overflow-y: scroll;
    min-height: 69vh;
}

.dropdown-menu.show {
    display: flex !important;
    height: 100% !important;
    flex-direction: column;
}

.requests .dropdown-menu.show {
    width: 350px !important;
}

.notifications .dropdown-item:focus,
.notifications .dropdown-item:active {
    background-color: #e9edf2 !important;
    color: #000 !important;
}

.notifications .dropdown-item {
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    /* background-color: #e9edf2; */
    font-size: 13px;
    display: flex;
    align-items: start;
    padding: 0.5rem 0.5rem;
}

.not_img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.unread-dot {
    color: #5644de;
    font-size: 20px;
    font-weight: 600;
}

.req-btns {
    font-size: 11px !important;
    padding: 7px !important;
    border-radius: 18px !important;

}

.req-text-style {
    /* text-decoration: none !important; */
    /* cursor: pointer !important; */
    color: black;
}

.not-text-style {
    text-decoration: none !important;
    color: black;
}