.fixed-audio {
    position: fixed;
    top: 79%;
    width: 100%;
    z-index: 99;
    left: 0;

}

.fixed-audio .rhap_header {
    color: #5345d6;
    font-size: 18px;
    font-weight: 600;
}

.fixed-audio .rhap_time,
.custom-audio-player .rhap_time,
.doc_audios-player .rhap_time {
    color: #5345d6 !important;
}

.fixed-audio .iconify,
.custom-audio-player .iconify,
.doc_audios-player .iconify {
    color: #5345d6 !important;
}

.fixed-audio .rhap_progress-filled,
.fixed-audio .rhap_volume-filled,
.custom-audio-player .rhap_progress-filled,
.custom-audio-player .rhap_volume-filled,
.fixed-audio .rhap_progress-indicator,
.fixed-audio .rhap_volume-indicator,
.custom-audio-player .rhap_progress-indicator,
.custom-audio-player .rhap_volume-indicator,
.doc_audios-player .rhap_progress-indicator,
.doc_audios-player .rhap_volume-indicator {
    background-color: #5345d6 !important;
}