/* .headercontainer
{
    background-color: #E0EBFF !important;

} */

.myvideos-content {
    margin-left: 35px;
    width: 96%;
    min-height: 84vh;
}

.myvideos-content-p {
    margin-left: 130px;
    width: 89%;
    min-height: 84vh;
}

.max-height {
    height: 50vh;
}

.ViewVideosContainer .footer-container {
    bottom: 0;
}

.publish-modal .modal-content {
    width: 96vw !important;
}

.publish-modal.show .modal-dialog {
    transform: none;
    display: flex;
    justify-content: center;
    --bs-modal-width: 96vw !important;
}

.publish-modal .modal-body>div {
    padding: 0 !important;
    margin: 0 !important;
}