.main {
    text-align: center;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    align-items: center;
}

.image-container {
    vertical-align: middle;
    display: flex;
    width: 60%;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    /* height: 23vh; */
}

.trans-container {
    border: 2px solid #CACED3;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 15%;
}

.trans-style {
    width: 20%;
    background-color: #CACED3;
    display: block;
    height: 50%;
    border-radius: 10px;
}

.trans-container.selected {
    border-color: #5644de !important;
}

.trans-style.selected {
    background-color: #5644de !important;
}

::-webkit-scrollbar .image-container {
    display: none;
}

.start-trans-container {
    width: 3%;
}

.trans-container.start {
    width: 68% !important;
}

.prev,
.next {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    margin: 0 auto;
    cursor: pointer;
    color: black;
    transition: 0.6s ease;
}

.next {
    margin-left: 5px;
}

.prev {
    margin-right: 5px;
}

.prev:hover,
.next:hover {
    color: white;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.sec7-img-style {
    /* width: 30% !important; */
    display: flex;
    width: 100%;
    /* margin-left: 3%; */
    /*  height: 170px; */
}

.sec7-img-style>img {
    /* width: 125px; */
    /* width: 55px;
    height: 100px; */
}

.remove-image {
    display: none;
    position: absolute;
    display: inline;
    top: 5px;
    right: 6px;
    border-radius: 10em;
    padding: 1px 5px 2px;
    text-decoration: none;
    font: 700 15px/15px sans-serif;
    background: #E54E4E;
    /* border: 3px solid #fff; */
    color: #FFF;
    /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5); */
    /* -webkit-transition: background 0.5s; */
    /* transition: background 0.5s; */
}

.remove-image:hover {
    background: #800000;
    padding: 1px 5px 2px;
    top: 5px;
    right: 6px;
}

.remove-image:active {
    background: #800000;
    top: 5px;
    right: 6px;
}

.wrapper {
    overflow: hidden;
    /* max-height: 21vh; */
    width: 100% !important;
}