.landing-container {
    /* margin-top: 7%; */
    /* z-index: 1; */
}

.first-sec-container {
    background-image: url('/public/assets/Bubbles1.png');
    /* background-image: url('/public/assets/banner-bg 2.svg'); */
    /* background-image: url('/public/assets/header-landing.svg'); */
    /* background-color: #f1ece2; */
    background-size: cover;
    /* background-size: contain; */
    background-position: center bottom;
    background-repeat: no-repeat;
    position: relative;
    /* object-fit: fill; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.first-sec-items {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* z-index: -100; */
}

.mokup_sec {
    background-image: url('/public/assets/subtract.png');
    position: relative;
    background-size: 100% 100%;
    /* object-fit: fill; */
    width: 100%;
    /* height: 530px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.first-sec {
    /* position: absolute; */
    width: 100%;
    /* height: 1103px; */
    /* top: -38px; */
}

.slogan {
    /* position: absolute; */
    /* width: 75%; */
    /* height: 91px; */
    /* left: calc(50% - 603px/2 - -153.5px); */
    /* top: 110px; */
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #000000;
    /* color: #FFFFFF !important; */


}

.text-style {
    /* position: absolute; */
    width: 75%;
    /* height: 268px; */
    /* left: calc(50% - 1030px/3.2); */
    /* top: 120px; */
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 160%;
    /* display: flex;
    align-items: center; */
    text-align: justify;
    text-align-last: center;
    letter-spacing: -0.03em;
    /* color: #FFFFFF !important; */
    color: #000000;

}

.text-justified {
    text-align: justify;
    text-justify: inter-word;
}

.btn-free {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    border: 1px solid #ffffff;
    z-index: 100;
    /* position: absolute; */
    width: 295px;
    height: 69px;
    /* left: calc(50% - 359px / 2 - -45.5px); */
    /* top: 340px; */
    color: var(--bs-white);
    background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
    border-radius: 100px;
    font-weight: 800;
}

/* .free-promocode {
    width: 60%;

} */

.plan-style {
    border-radius: 20px;
    background: radial-gradient(83.42% 148.99% at 14.81% 69.1%, rgba(181, 86, 255, 0.08) 37.5%, rgba(89, 86, 233, 0.08) 100%);
}

.payas-plan-style {
    background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%);
    color: #FFFFFF;
    border-radius: 20px;
}

.plan-style button {
    background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%);
    color: #FFFFFF;
    border: none !important;
}

.img-style {
    /* position: absolute; */
    width: 79%;
    /* height: 546px; */
    /* left: 125px; */
    /* top: 410px; */

}

.user-content-style {

    /* background-image: url('/public/assets/Rectangle.svg'); */
}

.share-and-user-types {
    position: relative;

    background-image: url('/public/assets/Bubbles2.png');
    align-items: center;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.user-type-style {
    /* background-color: transparent; */
    /* Rectangle */

    box-sizing: border-box;

    mix-blend-mode: multiply;

    background: linear-gradient(180deg, rgba(238, 238, 238, 0.4) 0.55%, rgba(205, 205, 205, 0.24) 100%);
    backdrop-filter: blur(21px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 25px;

    /* opacity: 70%; */
    /* background-image: url('/public/assets/Rectangle.svg'); */
}

.second-sec-container {
    /* background-image: url('/public/assets/subtract.png') !important; */
    /* background: linear-gradient(92.51deg, #5941EB 0.49%, #B556FF 100%); */
    position: relative;

    background-image: url('/public/assets/Bubbles1.png');
    /** edits in landing**/
    /* background-image: url('/public/assets/editSubtract.png'); */
    align-items: center;
    height: 100%;
    /* height: 500px; */
    /* background-size: cover; */
    background-size: contain;
    background-position: top bottom;
    /** edits in landing**/
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* z-index: -100; */
    justify-content: center;
    /* z-index: -100; */
    /* height: 383px; */
}

.mockup-img {
    z-index: 1000;
    position: relative;
}

.mockup-img .video-react .video-react-poster,
.mockup-img .video-react {
    background-color: #ffffff !important;
}


.second-sec-text {
    /* width: 25%; */
    display: flex;
    flex-direction: column;
    align-items: start;
}

.second-sec-img {
    /* position: absolute; */
    z-index: 1000;
    width: 100%;

    /* height: 377px; */
    /* left: 172px; */
    /* top: 0px; */
}

.second-sec-t {
    /* position: absolute; */
    /* width: 42%; */
    /* height: 131px; */
    /* left: 709px; */
    /* top: 0; */
    display: block;
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #000000;

}

.custom-bg {
    background-color: #5956E9 !important;
    text-align: start !important;
    padding: 10px !important;
    width: fit-content !important;
}

.item-list-style {
    background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
    padding: 10px;
}

.item-list-style>* {
    color: #FFFFFF;
}

.btn-transform {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    color: #ffffff;
    /* position: absolute; */
    /* width: 22%; */
    /* height: 69px; */
    /* left: calc(50% - 359px/2 + 281px); */
    /* top: calc(50% - 69px/2 + 167.5px); */
    border: none;
    background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%);

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
    border-radius: 100px;
}

.img-container {
    display: flex;
    justify-content: center;
    width: 35%;
}

.second-sec-p {
    /* position: absolute; */
    /* width: 50%; */
    /* height: 88px; */
    /* left: 709px; */
    /* top: 100px; */
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.03em;
    color: #000000;
}

.third-sec-container {
    width: 100%;
    /* height: 935px; */
    /* z-index: -100; */
    position: relative;
}

.why-us-style {
    background: radial-gradient(83.42% 148.99% at 14.81% 69.1%, rgba(181, 86, 255, 0.08) 37.5%, rgba(89, 86, 233, 0.08) 100%);
}

.third-sec-t {

    text-align: center;
    /* height: 55px; */

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 55px */


    color: #2F2F2F;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.third-sec {
    /* position: absolute; */
    width: 100%;
    /* left: calc(50% - 1728px/2); */
    /* top: 38px; */

    background: radial-gradient(83.42% 148.99% at 14.81% 69.1%, rgba(181, 86, 255, 0.08) 37.5%, rgba(89, 86, 233, 0.08) 100%)
}

.cards-container {
    /* z-index: -100; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.third-sec-cards {
    /* Content */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 30px;

    width: 100%;
    /* height: 346px; */

    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.1));

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}


.card-style {
    display: flex !important;
    flex-direction: column;
    /* justify-content: center !important; */
    justify-content: space-between !important;
    align-items: center;
    padding: 10px 10px;
    gap: 10px;
    /* width: 25%; */
    /* height: 300px !important; */
    background: #FFFFFF !important;
    border: 1px solid #DCDCDC !important;
    border-radius: 20px !important;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.card-tag {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    /* padding: 7px 28px 6px; */
    /* gap: 10px; */
    font-size: 13px;
    /* width: 25%; */
    /* gap: 10px; */
    /* height: 29px; */
    /* background: #FFFFFF; */
    /* border: 1px solid #000000; */
    /* border-radius: 24px; */
    /* flex: none; */
    /* order: 0; */
    /* flex-grow: 0; */
}


.card-title-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-heading {
    /* width: 70%; */
    /* height: 54px; */
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 105%;
    color: #2F2F2F;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: inline;
    inline-size: 75%;
    justify-content: left;
    align-items: center;
    text-align: left;
    /* word-wrap: break-word; */

}

.card-content {
    /* width: 70%; */
    /* height: 140px; */
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150.5%;
    color: #808080;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: flex;
    justify-content: left;
    align-items: start;
    text-align: left;

}

.card-footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    /* padding: 0px; */
    gap: 21px;
    /* width: 275px; */
    /* height: 57px; */
    flex: none;
    background-color: transparent;
    /* Inside auto layout */
    order: 2;
    flex-grow: 0;
}

.footer-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 13px;
    padding: 11px 19px 11px;
    gap: 10px;
    width: 114px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 30px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.footer-btn:active,
.footer-btn:hover {
    color: #FFFF;
    background: #5941EB;
    border: 1px solid #EEEEEE;
}

.footer-icon {
    width: 36px;
    /* height: 59px; */


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.forth-sec-container {
    position: relative;
    /* z-index: -100; */
    width: 100%;
    /* background-image: url('/public/assets/Subtract.svg'); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    /* background-position: top bottom; */
    background: linear-gradient(93.22deg, #5941EB 1.72%, #B556FF 100.06%);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.forth-sec-container>* {
    color: #FFFFFF;
}

.forth-sec {
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: space-evenly; */
    align-items: center;
    /* position: absolute; */
    width: 100%;
    /* height: 532px; */
    /* left: calc(50% - 1364px/2); */
    /* top: 128px; */

}

/* .forth-sec-t-container {
    width: 32%;
    z-index: -100;
} */

.forth-sec-t {
    /* position: absolute; */
    /* width: 70%; */
    /* height: 139px; */
    /* left: 205px; */
    /* top: 0px; */

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    /* or 48px */

    letter-spacing: -0.03em;

    color: #000000;
}

.forth-sce-cont {
    /* position: absolute; */
    /* width: 32%; */
    /* height: 114px; */
    /* left: 205px; */
    /* top: 70px; */

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    /* or 138% */

    letter-spacing: -0.03em;
    text-align: center;
    color: #000000;

}

.line {
    width: 50%;
    background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%);
    height: 0.5em;
}

.smal-txt-size {
    font-size: .8em;
}

/* .share-img-container {
    width: 30%;
} */

.share-img {
    /* position: absolute; */
    width: 100%;
}

.fifth-sec-container {
    position: relative;
    width: 100%;
    /* height: 385px; */
    /* background-image: url('/public/assets/Bubbles2.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 100%;
    /* z-index: -1; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.faq-container {
    /* background: linear-gradient(180deg, #FFFFFF 45.91%, #6E58F5 332.17%); */
    background-image: url('/public/assets/Bubbles1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.faq-container .accordion {
    --bs-accordion-bg: : url('/public/assets/Bubbles1.png') !important;
    background-size: cover;
    /* background-position: bottom center;
    --bs-accordion-bg-position: center center; */
    background-repeat: no-repeat;
}

.fifth-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    /* gap: 80px; */

    /* position: absolute; */
    width: 100%;
    /* height: 282px; */
    /* left: calc(50% - 1198.72px/2 + 21.36px); */
    /* top: 150px; */

}

.fifth-sec-t2 {
    font-size: 25px;
    width: 55%;
    text-align: center;
    font-weight: 800;
}

.stat-container {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: flex-start;
    justify-content: center;
    padding: 0px;
    /* gap: 144px; */
    /* z-index: -100; */
    width: 100%;
    /* height: 92px; */


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.stat1 {
    /* position: absolute; */
    text-align: center !important;
    /* width: 205.71px; */
    /* height: 55px; */
    /* left: 194.16px; */
    /* top: 0px; */

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 110%;
    /* identical to box height, or 55px */

    text-align: center;

    color: #5956E9;
}

.stat1-text {
    /* position: absolute; */
    width: 171px;
    text-align: center;
    /* height: 30px; */
    /* left: calc(50% - 177px/2 - 291.29px); */
    /* top: 120px; */

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150.5%;
    /* identical to box height, or 30px */


    color: #808080;
}

.fifth-sce-t {
    width: 30%;
    /* height: 110px; */

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    /* or 55px */

    text-align: center;

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.sixth-sec-container {
    position: relative;
    /* z-index: -100; */
    width: 100%;
    height: 610px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-image: url('/public/assets/bubbles4.png'); */
}

.sixth-sec {
    /* position: absolute; */
    /* width: 30%; */
    /* height: 371px; */
    /* left: 252px; */
    /* top: 5213px; */
    /* background: rgba(51, 51, 51, 0.05); */
    border-radius: 20px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}



.rate-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-right: 1% !important;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 3%;
    padding-bottom: 3%;
    background: rgba(51, 51, 51, 0.05);
    width: 100% !important;
    white-space: pre-wrap;
    margin-right: 1%;
}

.rate-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.rate-comma {
    width: 50px;
    height: 50px;
}

.rate-name {

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 60px; */
    /* identical to box height */

    text-align: justify;

    color: #000000;
}

.rate-place {

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 45px; */
    /* identical to box height */
    color: #5F5F5F;
    text-align: justify;
}

.sixth-sec-img-style {
    object-fit: fill;
    width: 73px;
    height: 73px;

    border-radius: 50%;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.sixth-sec-name {

    /* width: 355px; */
    /* height: 22px; */

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 110%;
    /* identical to box height, or 22px */


    color: #2F2F2F;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.sixth-sec-t {
    /* width: 75%; */
    /* height: 152px; */

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150.5%;
    /* or 38px */

    text-align: left;

    color: #2F2F2F;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.seventh-sec-container {
    position: relative;
    /* z-index: -100; */
    width: 100%;
    /* height: 550px; */
    display: flex;
    justify-content: center;
}

.seventh-sec {
    /* position: absolute; */
    /* background: linear-gradient(3.54deg, #5941EB 6.46%, #B556FF 97.35%); */
    box-shadow: 0px 0px 18px 0px rgba(50, 77, 215, 0.14);
    background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%);
    border-radius: 20px;
    /* background-color: #f7f8fa; */
    width: 60%;
    /* height: 496px; */
    /* left: calc(50% - 1608px/2 - 15px); */
    /* top: 30px; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.seventh-sec>* {
    color: #FFFFFF;
}

.rounded-top-left {
    border-radius: 20px 0 0 0;
}

.rounded-top-right {
    border-radius: 0 20px 0 0;
}

.rounded-bottom-right {
    border-radius: 0 0 20px 0;
}

.rounded-bottom-left {
    border-radius: 0 0 0 20px;
}

.pricing-bk-color {
    color: #FFFFFF;
    background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%);
}

.accordion-button:not(.collapsed) {
    /* background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%); */
    background-color: #f6e8fe;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
    color: #FFFFFF !important;
}

.sign-t {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 110%;
    /* identical to box height, or 55px */


    /* color: #FFFFFF; */


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.sign-p {

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 160%;
    /* identical to box height, or 32px */

    text-align: center;
    letter-spacing: -0.03em;

    /* color: #FFFFFF; */


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.mail-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 27px 6px 26px 21px;
    gap: 10px;

    /* position: absolute; */
    /* width: 95%; */
    height: 57px;
    /* left: 0px; */
    /* top: 130px; */
    border: none;
    /* background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%); */
    background-color: #FFFFFF;
    border-radius: 50px;
}

.mail-box-t {
    /* width: 138px; */
    /* height: 26px; */

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    /* line-height: 145.5%; */
    /* or 26px */

    text-align: center;

    color: #2F2F2F;
    /* color: #FFFFFF; */

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.mail-box-btn {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    /* padding: 11px 45px 20px; */
    /* gap: 10px; */

    /* position: absolute; */
    width: 40%;
    height: 48.45px;
    /* left: 355px; */
    /* top: 134.27px; */
    background-color: #B556FF;
    color: #FFFFFF;

    border: none;
    border-radius: 50px;
}

.step_no::before {
    border-left: 1px solid #FFFFFF;
    display: inline-block;
    content: '';
    position: absolute;
    left: 26px;
    height: 80%;
    width: 2px;
    /* margin-left: 84%; */
    /* z-index: -1000; */
    top: 32px;
}

@media(min-width:1400px) {


    .first-sec-container {
        /* height: 48vh !important; */
        /* margin-top: 10% !important; */
    }

    .first-sec-items {
        margin-top: 2rem;
        /* width: 60% !important; */
        height: fit-content !important;
    }

    .sec-img-width {
        width: 25% !important;
    }

    .second-sec-container {
        margin-top: 3rem !important
    }

    .mockup-img {
        width: 60% !important;
    }

    /* 
    .card-style {
        width: 11% !important;
    } */

    .landing-container {
        margin-top: 5%;
    }

    /* .mokup_sec {
        height: 710px !important;
    } */

    /* .seventh-sec {
        width: 50%;
    } */
}

@media (max-width: 600px) {

    .feedback .carousel-item {
        width: 100% !important;
        margin-left: 0 !important;
    }

    /* 
    .rate-comma {
        width: 30px;
        height: 30px;
    } */

    .btn-transform {
        width: 75%;
    }

    /* 
    .landing-container {
        margin-top: 40%;
    } */

    /* .card-style {
        width: 63%;
    } */

    .text-style,
    .slogan {
        width: 90%;
    }

    .slogan {
        font-size: 50px;

    }

    /* .first-sec-container {
        height: 790px;
        margin-top: 20% !important;
    } */

    .second-sec-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .second-sec-text {
        width: 80%;
    }

    .third-sec-container {
        height: 100% !important;
    }

    /* 
    .third-sec-cards {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center;
        height: 100% !important;
        margin-top: 7% !important;
    } */

    /* .forth-sec {
        flex-direction: column !important;
    } */

    /* .forth-sec-t-container {
        width: 70%;
    } */

    .share-img-container {
        width: 80%;
        margin-bottom: 25% !important;
    }

    .fifth-sce-t {
        width: 70%;
    }

    .sixth-sec {
        width: 90%;
    }

    /* .mail-box {
        width: 343px;

    } */

    .forth-sec-container {
        height: 100%;
    }

    .fifth-sec-t2 {
        font-size: 15px;
    }

    .stat1 {
        font-size: 15px;
    }

    .stat1-text {
        font-size: 10px;
        width: 110px;
    }

    .stat-container {
        justify-content: center;
    }

    .mail-box-t {
        font-size: 12px;
    }
}