.v-scrolledParent {
    overflow-x: scroll;
}

.v-scrolledParent .video-card {
    min-width: 280px;
}

.v-container {
    overflow: hidden;
}

.v-box-height {
    background-color: #EFEFF2;
    /* height: 730px; */
}

.video-suggestion-container {
    border-radius: 10px;
    border: 1px solid #E2E8F0;
    background: #F8F9FA;
}