.search-options {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: static;
    float: none;
    border-radius: 20px 0px 0px 20px !important;

}

.search-form .form-select {
    background-color: #f8f9fa;
    width: auto;
}

.search-form {
    display: flex;
    position: static;
    float: none;
    -webkit-box: 1;
    -moz-box: 1;
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px;
    width: 50% !important;
}

/* .search-form>input {
    border-radius: 20px;
} */

.remove-style {
    border: none;
    color: #FFFFFF;
    background-color: transparent;
}

.search-icon {
    /* position: absolute; */
    cursor: pointer;
    /* top: 11px; */
    /* right: 25.3%; */
    border-radius: 0px 20px 20px 0px;
    background-color: #5644de;
    color: #FFFFFF;
    width: 60px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-form input {
    border-radius: 8px;
    border: 1px solid #E2E8F0;
    font-size: 14px;
}

.search-form input::placeholder {
    color: #CACED3;
}

.search-form input:focus,
.search-form input:active {
    outline: none !important;
    border-color: #dee2e6;
    box-shadow: none;
}

.loading-search {
    height: 55vh;
}

@media (max-width: 992px) {

    .search-form {
        width: 100% !important;
    }
}