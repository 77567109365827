.feeds-content {
    margin-left: 50px;
    /* width: 96%; */
    min-height: 84vh;
}

.feeds-content-p {
    margin-left: 145px;
    width: 88%;
    min-height: 84vh;
}

.no-search-res-style {
    height: 39vh;
}