.video-details-container {
  border-radius: 10px;
  border: 1px solid #E2E8F0;
  background: #F8F9FA;
}

.video-details-content {
  /* max-height: 51vh; */
  /* min-height: 51vh; */
  overflow-y: auto;
}

.generated-by,
.generation-date {
  color: #999;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.user-image {
  width: 35px !important;
  height: 35px !important;
}

.user-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.user-name {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.user-university {
  color: #999;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.video-title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.video-title.unloaded {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-container.unloaded {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.video-interest {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}



.info-title {
  color: #999;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.info-content {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* white-space: nowrap; */
}

.view-doc {
  color: #5644de
}

.video-desc .unloaded {
  text-decoration: none;
  color: #999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.video-desc .loaded {
  text-decoration: none;
  color: #999;
}

.show_link {
  white-space: nowrap;
  color: #5644de;
  text-decoration: underline;
}

.authors .show_link {
  font-size: 14px;
}

.btn-color {
  background-color: #5644de !important;
  border-color: #5644de !important;
}

.fontcolor {
  color: #999999
}

.t-style {
  position: absolute;
  top: 0;
  left: 0;
}

.style-arrow {
  margin-left: 25%;
}

.font-style {
  font-size: small;
}

.div-width {
  width: 100% !important;
}

.border-color {
  border-color: #999999;
}

.details-width {
  width: 70% !important;
}


.play-content-p {
  margin-left: 130px;
  width: 88%;
}

.play-content-p input {
  width: 540px !important;
}

.newPlaylist .from-style>.input-box {
  width: auto !important;
}

.video-actions>.btn {
  font-size: 12px !important;
  font-weight: 600 !important;
}