.box-outline {
    /* margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
    width: 60%;
    height: 60%; */
    /* border: solid 2px gainsboro; */
    /* border-radius: 5px; */
    /* padding-inline: 20px; */
    /* padding-block: 30px; */
}

.search-input-style>input:active,
.search-input-style>input:focus {
    outline: none !important;
    box-shadow: none;
    border-color: #dfe2e6 !important;
}

.copy-input-style>input {
    border-radius: 20px !important;
}

.search-input-style>input {
    border-radius: 0px 20px 20px 0px !important;
}

.search-input-style>span {
    border-radius: 20px 0px 0px 20px !important;
}

.share-modal.modal-header {
    align-items: start !important;
    margin: 0 !important;
    padding-bottom: 0 !important;
}

.header-weight-bold {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.03em;
}

.header-fineText {
    color: rgb(106, 108, 108);
    font-weight: 300;
    font-size: small;
    margin-top: -10px;
}

.users-outline {
    margin: auto;
    width: 100%;
    border: solid 2px gainsboro;
    border-radius: 10px;
    padding-inline: 20px;
    padding-block: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dot {
    height: 35px;
    width: 35px;
    /* background-color: #bbb; */
    border-radius: 50%;
    display: inline-block;
}

.user-result {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    border-radius: 3px;
    /* gap: 10px; */
    padding: 4px;
}

.user-result:hover {
    background-color: rgb(202, 205, 209);
}

.user-name {
    position: relative;
    bottom: 3px;
    font-weight: 600;
}

/* .user-tag {
    position: relative;
    top: 25px;
    right: 55px;
} */

#buttons-group {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: end;
}

.username-fineText {
    color: rgb(106, 108, 108);
    font-weight: 300;
    font-size: small;
}

.btn-share-color {
    background-color: #5644de !important;
    color: #FFFFFF !important;
}

.msg_alert {
    position: absolute !important;
    top: 35%;
    left: 34%;
    padding-top: 12px !important;
    padding-bottom: 4px !important;
}

.box-outline .alert-heading {
    font-size: 12px;
}