.btn-style {
  background-color: #5644de !important;
  border-color: #5644de !important;
  /* margin-right: 4%; */
}

.add-comment input {
  background-color: transparent;
  border: 1px solid #E2E8F0;
  height: 40px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
}

.post-color {
  background-color: #FFFFFF
}