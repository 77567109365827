.btn-login-style {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 25px;
    gap: 10px;
    width: 27%;
    /* height: 48px; */
    color: #FFFF;
    background: #5941EB;
    border: 1px solid #EEEEEE;
    border-radius: 60px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}