  .title-list-header {
    color: #999999 !important;
  }

  .title-list-height {
    height: 337px;
  }

  .btn-link.active {
    color: #2F2F2F !important;
    background-color: #B9AFF7 !important;
    border-color: none !important;
  }

  .btn-link {
    text-align: left;
    color: #D9D9D9 !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .text-size {
    width: 100% !important;
    background-color: #505050 !important;
    color: #FFFFFF !important;
    overflow: hidden !important;
    min-height: 100% !important;
    height: 300px !important;
  }

  .p-text-color {
    color: #999999;
  }

  .summ-list {
    width: 863px !important;
  }

  .summ-item {
    background-color: #F9F6EE;
  }

  .titles-links {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 90%;
    /* -ms-overflow-style: none; */
    /* scrollbar-width: none; */
  }

  .titles-links button {
    width: 95%;
  }

  /* .titles-links::-webkit-scrollbar {
    display: none;
  } */

  .titlelist div {
    width: 100%;
  }

  .note_style {
    color: #5644de;
  }