/* .videosGrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
} */

.videosGrid .videosRow {
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    align-items: center;
    /* width: 100%; */
    margin-top: 1rem;
    gap: 0.5rem;
}

.videosGrid>.videosRow .card-loader {
    width: 205px !important;
}

.card-loader {
    background-color: rgba(238, 238, 238, 0.5) !important;
}