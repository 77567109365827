.auth-img-container {
    background-image: url("../../../public/assets/auth_img.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 18px;
}

.auth-img-container .content {
    color: #fff;
}