/* .videos {
    display: flex;
    flex-direction: row;
    justify-content: start;
    overflow: auto;
    height: 18rem;
    gap: 1rem;
    padding-left: 4rem;
    overflow: hidden;
    position: relative;
} */

/* .feed-links {
    text-decoration: none;
    color: black;
    background-color: #F6F4FB;
    padding: 8px;
    font-size: 15px;
    border-radius: 20px;
} */

.videoHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding-left: 4rem;
    padding-right: 4rem; */
    margin-top: 0.25rem;
}

#tab {
    /* padding-left: 4rem; */
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.tabLink {
    font-size: 1.25rem;
    font-weight: 600;
    color: grey;
}

.videoGroupTitle {
    font-size: 1.5rem;
    font-weight: 600;
}

.overlay-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.home-content {
    width: 100%;
    min-height: 84vh;
}

.home-content-p {
    margin-left: 130px;
    width: 89%;
    min-height: 84vh;
}

.home-banner {
    width: 100%;
    height: 200px;
    margin-top: 60px;
    border-radius: 15px;
    background-image: url("../../assets/banner-background.x2.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.home-banner h3 {
    color: white;
    font-size: 30px;
    text-transform: capitalize;
    width: 50%;
}

.home-banner .home-generate-btn,
.home-banner .home-generate-btn:hover {
    border-radius: 0.5rem !important;
    background: rgba(0, 0, 0, 0.48) !important;
    color: white !important;
    border: 0px !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    padding: 0.75rem 1rem;
}

.home-title h3 {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.home-title p {
    color: #CACED3;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


@media (max-width:570px) {
    .home-banner h3 {
        font-size: 20px;
        width: 100%;
    }
}