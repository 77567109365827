.toolbox-style {
    height: 100vh;
    background: #F1F5F9;

}

.canvas-style {
    /* background-color: #f5f5f7; */
    /* height: 68vh; */
    /* width: 60vw; */
    /* height: 60vh; */
    width: 100%;

    /* z-index: -1; */
}

.actions-style {
    background-color: #F8F9FA;
}

.canvas-container {
    /* width: 54vw; */
    /* width: 50vw; */
    /* width: 100%; */
    /* height: 55vh; */
}

.controls-width {
    width: 10%;
}

/* .controls-width.expand {
    width: 10%;
} */

.canvas-container-expand {
    /* width: 100%; */
    /* width: 81vw; */
    /* height: 66vh; */
    /* width: 75vw; */
}

.canvas-style-expand {
    width: 100%;
}

.search_bk_icon {
    /* position: absolute; */
    /* right: 0; */
    /* top: 0; */
    background-color: #ffffff;
    /* width: 3vw; */
    /* height: 6vh; */
    border-radius: 15%;
    box-shadow: 0px 4px 16px 0px #00000040;
}



/* .search_bk_icon svg {
    width: 6vw;
    height: 6vh;
} */

.toolbox-label {
    width: auto;
    height: auto;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #000000;
    /* margin-bottom: -15px; */
}

.canvas-width {
    /* width: 75%; */
}

.toolbox-width {
    width: 30%;
}

.track-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 15%;
    box-shadow: 0px 4px 16px 0px #00000040;
    /* width: 17vw !important; */
}

/* .track-controls {
    position: absolute;
    top: 66%;
    left: 76%;
}

.track-controls.expand {
    left: 85% !important;
} */

.toolbox-input {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 5px 8px;
    gap: 10px;
    background: #F1F5F9;
    border-radius: 8px;
    letter-spacing: -0.03em;
    color: #666F79;
}

.border-input {
    width: 20%;
}

.canvas-container .font-style {
    width: 40%;
}

.font-input {
    width: 20%;
}

.text-area-style {
    background: #F1F5F9;
    border-radius: 8px;
    letter-spacing: -0.03em;
    color: #666F79;
    height: 50hv;
}

.video_bkg {
    width: 100%;
    display: flex;
    justify-content: start;
}

.video_bkg .image-container {
    width: 100% !important;
}

.video_bkg .sec7-img-style {
    /* width: 14% !important;
    min-height: 15vh;
    height: 16vh; */
}

.thumb-scroll {
    width: 96%;
    background-color: #F8F9FA;
    border-top: 1px solid #E2E8F0;
    /* border-radius: 15px; */
}

.video_bkg .sec7-img-style>img {
    width: 100%;
    border-radius: 10px;
    /* height: 14vh; */
}

.video_bkg .sec7-img-style.selected {
    border: 2px solid #5644de;
    border-radius: 10px;
}

.gen-title-style {
    font-weight: 600;
    font-size: 18px;
}

.popupSearchBK #tab {
    padding: 0 !important;
    margin: 0 !important;
    gap: 0 !important;
    height: fit-content !important
}

.search-grid-img {
    height: 200px;
    object-fit: cover;
}