.viewer {
  position: relative;
}

.video-react {
  width: 100% !important;
  height: 480px !important;
}

.custom-controlbar {
  width: 20%;
}

.loadind-viewer-container {
  /* width: 755.32px; */
  height: 66vh;
  background-color: #EFEFF2;
  opacity: 0.5;
}