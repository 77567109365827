/* .h-list-style {
    margin-left: 3% !important;
} */
.header-style {
    /* z-index: -1 !important; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 186px;
    z-index: 10000;
    position: fixed;
    width: 92%;
    /* height: 68px; */
    left: 4%;
    top: 4%;
    border: none !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.header-style-white {
    /* background-color: rgba(255, 255, 255, 0.8) !important; */
    /* box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1); */
}

.h-logo-size {
    width: 65%;
}

.h-list-style a {
    text-decoration: none;
    color: black;
}

.btn-sign {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 25px;
    gap: 10px;
    /* width: 70%; */
    /* height: 48px; */
    color: #FFFF;
    background: #5941EB;
    border: 1px solid #EEEEEE;
    border-radius: 60px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.btn-login {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    color: black;
    padding: 14px 25px;
    gap: 10px;
    /* width: 40%; */
    /* height: 48px; */
    background-color: #FFFF;
    border: 1px solid #E0E0E0;
    border-radius: 60px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

@media (max-width:1100px) {
    /* .header-style {
        top: 2%;
    } */
}

.land-drop .dropdown-item:active,
.land-drop .dropdown-item:focus {
    background-color: #5941EB !important;
}