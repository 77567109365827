.footer-cont {
    background: linear-gradient(180deg, #FFFFFF 45.91%, #6E58F5 332.17%);
    z-index: 1;
    /* background-image: url('/public/assets/footer-bg 1.svg'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 100%;
    width: 100%;
    position: relative;
}

.text-decoration {

    font-weight: bold;
    /* color: #5644de; */
}

.logo-text {
    color: #5644de !important;
    font-weight: 700;
    font-size: large !important;
}

.footer-container>* {
    /* color: #FFFFFF !important; */
}

.text-shadow {
    text-shadow: 1px 1px;
}

.f-logo-size {
    /* position: absolute; */
    /* border-radius: 50px; */
    width: 311px;
    /* height: 40px; */
    /* left: 96px; */
    /* top: 8042px; */
    /* background-color: #F5F5F5; */
    /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1) */
}

.social-link {
    text-decoration: none;
    color: #000000 !important;
}

@media screen and (min-width:1350px) {
    .footer-cont {
        background-position: center !important;
    }
}