.video-card {
    padding: 0.25rem;
    border-radius: 10px;
}

.thumbnail {
    border-radius: 10px;
    margin: auto
}

.thumbnail>.poster {
    margin-top: 0 !important;
}

.videoTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
}

.videoTitle>a {
    text-decoration: none;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user-area {
    margin-top: 10px;
}

.username {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 10px;
    font-weight: 700;
}

.video-date {
    color: #999999;
    font-size: 12px;
}

.video-category {
    color: #999999;
    font-size: 14px;
}

.publish-placeholder {
    background-color: black;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    top: 1%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pub-text-placeholder {
    color: #FFFFFF;
    font-weight: 600;
}

.publish-placeholder .CircularProgressbar {
    width: 25% !important;
}

.publish-placeholder .CircularProgressbar .CircularProgressbar-text {
    fill: #FFFFFF !important;
}

.publish-placeholder .CircularProgressbar .CircularProgressbar-path {
    stroke: #FFFFFF !important;
}

.video-card .thumbnail .poster {
    width: 100% !important;
    height: 25vh !important;
}

.video-card .thumbnail .poster>img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px;
    object-fit: cover;
}

.save-style {
    cursor: pointer;
}

.authorImage {
    width: 45px !important;
    height: 45px !important;
    margin-right: 2px;
    flex-shrink: 0;
    object-fit: fill;
}

.card-save-btn {
    font-size: 12px !important;
}


.video-card .stats-font span {
    font-size: 14px;
    color: #999;
}

.liked {
    color: #5644DE !important;
}

.not-liked {
    color: #999 !important;
}

.delete-menu .dropdown-toggle::after {
    display: none !important;
}

.delete-menu .dropdown-menu.show {
    height: fit-content !important;
}

.delete-menu .dropdown-item:active {
    background-color: #5644DE !important;
}