.terms-conditions {
  /* width: 373px; */
  /* height: 22px; */
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  align-items: center;
  font-style: italic;
  color: #999999;
}

.terms-conditions:hover {
  cursor: pointer;
}

a#termConditionLink {
  color: #5644de;
}

.terms-conditions .form-check-input {
  border-color: #5644de !important;
}

.terms-conditions .form-check-input:checked {
  background-color: #5644de !important;
}