#slider_tooltip {
    z-index: 100000 !important;
}

.as_y_go {
    background: linear-gradient(157.46deg, #B330E1 -0.64%, #5644DE 122.59%);
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    color: #FFFFFF;
}

.max-label,
.min-label {
    font-size: 8px;
}

.enter_plan {
    background-color: #F8F9FA;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
}

.theme-color-txt {
    color: #5644DE;
}

.small-color-txt {
    color: #999999;
}

.enter-specs {
    font-size: 12px;
    display: flex;
    flex-direction: column;
}

.font-size-small {
    font-size: 15px;
}