/* .elipse {
    width: 14.2px;
    height: 14.2px;
    background: #FFFffF;
    border-radius: 50%;
}

.feedback .carousel-item {
    width: 55% !important;
    margin-left: 26%;
}

.scroll {
    width: 100%;
    overflow-x: auto;
}

.feed-container {
    gap: 2%;
    width: 100%;
    margin-left: 40%;
}
 */

.feedback {
    text-align: center;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.f-image-container {
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    /* margin-left: 25%; */
}

.f-prev,
.f-next {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    margin: 0 auto;
    cursor: pointer;
    color: black;
    transition: 0.6s ease;
}

.f-next {
    margin-left: 5px;
}

.f-prev {
    margin-right: 5px;
}

.f-prev:hover,
.f-next:hover {
    color: white;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.f-img-style {
    width: 45%;
    display: inline-block;
    /* margin-left: 3%; */
    /*  height: 170px; */
}

.scroller>a>svg {
    border: 1px solid black;
    border-radius: 50%;
}

.rate-container.non-active {
    width: 75% !important;
    height: 225px;
    margin-left: 14%;
}


.rate-header.non-active {
    margin-top: 30px !important;
}

.rate-comma.non-active {

    width: 40px !important;
    height: 40px !important;
}

.rate-name.non-active {
    font-size: 15px !important;
}

.rate-place.non-active {
    font-size: 10px;
}

.sixth-sec-t.non-active {
    font-size: 10px;
    /* width: 80%; */
}

.sixth-sec-img-style.non-active {
    width: 30px;
    height: 30px;
    margin-bottom: 25px;
}

.f-img-style.non-active {
    width: 40% !important;
}