.coningsoon-container {
    /* display: flex; */
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    background-image: url('/public/assets/comingsoon.png');
}

.notfound-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #edeef2;
}

.notfound-container .home_container {
    background-color: #edeef2;
}

.notfound-center {
    height: 90vh;
    width: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    background-image: url('/public/assets/NotFound.png');
}

.home_btn {
    background-color: #6142a9;
    color: #ffffff;
    text-decoration: none;
    padding: 6px;
    font-size: 17px;
    border-radius: 20px;
    /* position: absolute;
    bottom: 10vh;
    left: 46% */
}

.coningsoon-img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: contain;
}

.prv-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #edeef2; */
}

.prv-img {
    height: 80vh;
    width: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    background-image: url('/public/assets/private.png');
}

.prv-text {
    color: #5345d6;
    font-size: 1.5rem;
    font-weight: 700;
}