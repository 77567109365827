.playlistsGrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    /* width: 85%; */
}

.playlistsGrid .playlistsRow {
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    align-items: center;
    /* width: 100%; */
    margin-top: 1rem;
    gap: 0.5rem;
}

.playlistsGrid>.playlistsRow .card-loader {
    width: 182px !important;
}

.playlistCard {
    width: 17%;
    padding: 0.25rem;
    height: 11rem;
    background: #f5f5f7;
    border-radius: 10px;
}

.playlist-thumbnail {
    /* width: 100%; */
    /* height: 50%; */
    background: #ffffff;
    border-radius: 10px;
    margin: auto
}

.videoStats {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: space-between;
    align-items: center
}

.timeStamp {
    position: relative;
    top: 70%;
    left: 85%
}

.playlist-thumbnail>.poster {
    margin-top: 0 !important;
}

.playlistTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    width: 88%;
    font-size: 12px;
    font-weight: 700;
}

.playlistTitle>a {
    text-decoration: none;
    color: #000000;
    /* word-wrap: break-all; */
    /* height: 28px; */
    overflow: hidden;
    white-space: nowrap;
    /* Don't forget this one */
    text-overflow: ellipsis;
}

.authorRow {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.stats-font {
    font-size: 12px;
}

.username {
    overflow: hidden;
    white-space: nowrap;
    /* Don't forget this one */
    text-overflow: ellipsis;
    font-size: 10px;
    font-weight: 700;
}

.videoDate {
    /* margin-left: 0.5rem; */
    color: #999999;
    font-size: 10px;
}

.playlist-thumbnail .poster>img {
    width: 100% !important;
    height: 110px !important;
    border-radius: 10px;
    border: 2px solid #5644de;
    /* z-index: -1; */
}

.save-style {
    cursor: pointer;
}

.authorImage {
    /* border-radius: 50%;
    border-width: 0; */
    /* background-color: black; */
    /* width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; */
    width: 45px !important;
    height: 45px !important;
    margin-right: 2px;
    flex-shrink: 0;
    object-fit: fill;
}

@media (min-width:2500px) {
    .videoCard {
        width: 11.5% !important;
    }
}

@media (max-width:2500px) {
    .videoCard {
        width: 14.5% !important;
    }
}

@media (max-width:1700px) {
    .videoCard {
        width: 19.5% !important;
    }
}