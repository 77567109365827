.title-style {
  /* margin-top: 30% !important; */
  justify-content: center;
  display: flex;
  align-items: start;
  flex-direction: column;
}

.title-style>p>a {
  color: black;
  font-size: medium;
  font-weight: bold;
  text-decoration: none;
}

.titledetails-style {
  /* margin-top: 30% !important; */
  color: lightgray
}

.item-container {
  border-bottom: solid 1px #e4e6ee;
}

.font-style {
  color: #999999;
  font-size: 14px;
}

.font-small {
  font-size: 12px;
}

.bg-size {
  font-size: 8px !important;
}

.delete_width {
  /* width: 3% !important; */
  display: flex;
  align-items: center;
  justify-content: center;
}