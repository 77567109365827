.playlist-button {
    /* margin: 3px; */
    padding: 3px;
    border-radius: 20px;
}

.play-border {
    background-color: #B9AFF7;
}

.playlist-item {
    background-color: #d9d9d9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.playlist-count {
    color: #5345d6;
    font-size: 15px;
    font-weight: 700;
}