.moving-lines-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    flex-direction: column;
    /* Adjust the height as needed */
}

.p-moving-line,
.t-moving-line {
    width: 2px;
    /* Adjust the width of the lines */
    height: 20px;
    /* Adjust the height of the lines */
    background-color: #000;
    /* Adjust the color of the lines */
    margin: 0 5px;
    /* Adjust the spacing between the lines */
    animation: moveLines 1.5s infinite linear;
    /* Adjust the animation duration */
}

@keyframes p-moving-line {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
        /* Adjust the vertical movement */
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes t-moving-line {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
        /* Adjust the vertical movement */
    }

    100% {
        transform: translateY(0);
    }
}