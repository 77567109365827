.uploadbox {
    /* margin: auto; */
    /* margin-top: 10%; */
    /* margin-bottom: 10%; */
    width: 75%;
    /* border: solid 2px gainsboro;
    border-radius: 5px;
    padding-inline: 60px;
    padding-block: 30px; */
}

.fineText {
    color: rgb(106, 108, 108);
    font-weight: 300;
    font-size: small;
}

.dropbox {
    margin: auto;
    border: dashed 2px gainsboro;
    border-radius: 5px;
    padding: 30px;
}

.dropzone {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.dropzone:hover {
    cursor: pointer;
}

.upload-title {
    background: #F5F5F5;
    border-radius: 5px;
}

.dragdrop-upload-container .upload-title {
    border-color: #c3cbd4 !important;
    border-radius: 9px;
    box-shadow: 0px 0px 4px 4px #d5ddf5;
    width: 57vw;
    padding-left: 1%;
    padding-right: 1%;
    background-color: white !important;
}

.upload_image {
    width: 55% !important;
}

.upload_image_container {
    display: flex;
    justify-content: center;
}

.upload-title-p {
    font-weight: 500;
}

.deletex:hover {
    cursor: pointer;
}

.dragdrop-upload-container {
    /* background-image: url('/public/assets/Bubbles1.png'); */
    object-fit: fill;
    background-size: contain;
}

.dragdrop-upload-container .upload-placeholder span {
    text-decoration: none !important;
}

.dragdrop-upload-container .file-container {
    border: 1px solid #E2E8F0 !important;
    color: #5644de !important;
    border-radius: 10px;
    /* width: 60vw; */
    align-items: center;
    background-color: #F8F9FA;
}

.dragdrop-upload-container .file-details-container {
    display: flex;
    flex-grow: 0 !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 5%; */
}

.dragdrop-upload-container .file-details-container .file-types {
    overflow: visible;
    font-size: 13px;
    color: #8d9bb3;
    max-width: fit-content !important;
}

.dragdrop-upload-container .file-details-container .upload-placeholder {
    font-size: 15px;
    font-weight: 600;
    color: #5644de;
}


.dragdrop-upload-container .newsvg>svg>path {
    fill: #5644de !important;
}