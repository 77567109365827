.lbl-container {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 18px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #D9D9D9;
}

.box-item {
  border: 0 !important;
}

.box-header {
  color: #999999 !important;
}

/* Hide the browser's default checkbox */
.lbl-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 13px;
  left: 90%;
  height: 15px;
  width: 15px;
  background-color: #eee;
  /* margin-left: 93% !important; */
  border-radius: 15px;
}

/* On mouse-over, add a grey background color */
.lbl-container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.lbl-container input.checked~.checkmark {
  background-color: #5644de !important;
}

.lbl-container.checked {
  border-color: #5644de !important;
  color: #2F2F2F !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.lbl-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.lbl-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.list-height {
  height: 305px;
  /* overflow: hidden; */

}

.scrolled-parent {
  height: 100%;
  margin-right: -50px;
  /* Maximum width of scrollbar */
  padding-right: 50px;
  /* Maximum width of scrollbar */
  overflow-y: scroll;
}

.align-text {
  padding-left: 13%;
}

.disabled-style {
  background-color: #eaedf0 !important;
  cursor: not-allowed !important;
}

.note_style {
  color: #5644de;
}