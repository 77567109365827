.vr-container {
    background-color: #FFFFFF
}

.desc-style {
    /* margin-top: 30% !important; */
    color: lightgray;
    height: 40px;
    overflow: hidden;
    font-size: 13px;
}

.v-details-width {
    width: 60% !important;
}

.v-user-image {
    width: 22px !important;
    height: 22px !important;
    margin-right: 2px;
    flex-shrink: 0;
    object-fit: fill;
    border-radius: 50%;
}

.vr-container .poster {
    border: 3px solid #5644de;
    border-radius: 10px;
}

.user-fontcolor {
    color: #999999;
    font-size: 14px;
}

.v-title-style {
    /* margin-top: 30% !important; */
    justify-content: center;
    display: flex;
    align-items: start;
    flex-direction: column;
}

.v-title-style>p>a {
    color: black;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 4rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

/* @media (max-width:1700px) {

    .recent-v .poster>img,
    .recent-v .poster-img {
        
width: 110px;
height: auto;
}
}

*/