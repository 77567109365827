.tab-link.active {
    color: #5644de !important;
    background-color: none !important;
    border-color: none !important;
    border-bottom: 3px solid #5644de !important;
}

.tab-link>.badge {
    background-color: #5644de !important;
}

.tab-link {
    color: #5644de !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.margin-style {
    margin-Left: 3px;
}

.video-header {
    border-bottom: solid 1px #e4e6ee;
    /* margin: 2%;
      padding: 0.5%; */
    font-size: 12px;
    font-weight: bold;
    /* border-radius: 8px; */
}

.tabcontent-container {
    border: solid 1px #e4e6ee;
    border-radius: 8px;
}

/* .content-playlist .poster-img,
.content-playlist .poster>img {
    width: 90px !important;
} */