.logo-style {
  color: #5644de !important;
  font-weight: 700;
  font-size: large !important;
}

.user_menu .dropdown-toggle::after {
  display: none;
}

.profile-img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.header2-style {
  position: fixed;
  width: 100%;
  background: #FFFFFF;
  border: none !important;
  z-index: 1000;
}

.nav-link:active {
  color: #5644de;
}

.list-style {
  margin-left: 3% !important;
}

.rounded-circle {
  object-fit: fill;
}

.logo-size {
  width: 220px;
}

.btn-gen {
  background-color: #5644de !important;
  width: 150px
}

.nav-links-color {
  color: black !important;
}

.start-gen {
  font-size: 14px !important;
  white-space: nowrap;
}

.email-style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  color: #5B5B5B;
}

.username-style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.03em;

  color: #000000;
}

.header2-style .dropdown-item:active {
  background-color: #B9AFF7 !important;
}

.dropdown-item-style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  color: #000000 !important;
}

@media (max-width: 992px) {
  .btn-gen {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .btn-gen {
    width: 100% !important;

  }


}

/* @media (min-width:1600px) {
  .search-icon {
    right: 400px !important;
  }
} */