body {
  margin: 0;
  font-family: "Inter" !important;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
* {
  font-family: "Inter" !important;
}

input:focus,
input:focus-visible {
  border-color: #5644DE !important;
}


.text-gray-color {
  color: #999999;
}

.text-theme-color {
  color: #5644de;
}

.modal.show .modal-dialog {
  z-index: 9999;
}

.txt-input-style {
  border-radius: 20px 20px 20px 20px !important;
  border: none;
}

.primary_btn {
  width: 12%;
  font-weight: bold;
  /* height: 40px; */
  border-radius: 10px !important;
  background-color: #5644de !important;
  color: #ffffff;
  border: 2px solid #5644de !important;
}

.reg-button {
  width: 12%;
  font-weight: bold;
  /* height: 40px; */
  border: 2px solid #5644de !important;
  border-radius: 10px;
  color: #5644de;
  background-color: #ffffff;
}

.txt-input-style:active,
.txt-input-style:focus {
  border: none !important;
  outline: none !important;
}

.txt-input-select>.dropdown-toggle {
  border-radius: 0 20px 20px 0 !important;
}


.form-check-input:checked {
  background-color: #5644de !important;
  border-color: #5644de !important;
}

.f_size_small {
  font-size: 13px;
}

/* 
#root:has(.spinner-container) {
  opacity: 0.3;
  
} */


.loading {
  opacity: 0.3;
}

.top-m {
  margin-top: 100px !important;
}

/* .btn {
  border-radius: 100px !important;
  border: none !important;
} */


.btn-outline-primary,
.btn-outline-primary:hover {
  color: #5644DE !important;
  border-radius: 5px !important;
  border: 1px solid #5644DE !important;
  background: transparent !important;
}

.btn-primary,
.btn-primary:hover {
  color: #fff !important;
  border-radius: 5px !important;
  border: 1px solid #5644DE !important;
  background: #5644DE !important;
}

@media (min-height:570px) {
  .pageContent-h {
    min-height: 370px;
  }
}

@media (min-height:1100px) {
  .pageContent-h {
    min-height: 710px;
  }
}

@media (min-height:1600px) {
  .pageContent-h {
    min-height: 1570px;
  }
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5644de !important;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.space-around {
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;

}

.button-style {
  background-color: #5644de !important;
  color: #ffffff;
}

.visible-scrolledParent {
  height: 100%;
  overflow-y: scroll;
}

.link-pointer {
  cursor: pointer !important;
}