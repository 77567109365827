.publish_wrapper label {
    width: 100% !important;
}

.publish_wrapper input {
    width: 100% !important;
}

.publish_wrapper>.label-div .input-label {
    width: 624px;
    height: 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #000000;
    margin-bottom: -15px;

}

.publish_wrapper .btn {
    height: 50px;
}

.style-button {
    /* width: 30%; */
    font-weight: bold;
    /* height: 40px; */
    background-color: #5644de !important;

}

.btn-font-size {
    font-size: small !important;
}

.publish_wrapper .loadind-viewer-container {
    width: 555.32px;
    height: 324.87px;

}

.desc-style {

    color: #999999;
    font-size: 12px;
}

.desc-info {
    color: #0c5460;
    font-size: 12px;
}

.publish_wrapper .label-div {
    justify-content: end;
}

.publish_wrapper .error-msg {
    font-size: 12px !important;
}