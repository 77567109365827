.switch-style.form-switch {
    display: flex;
    align-items: center;
}

.switch-style label {
    margin-left: 0.5em;
}

.switch-style>.form-check-input {
    /* width: 60px !important; */
}

.switch-style>.form-check-input:checked {
    background-color: #5644de;
    border-color: #5644de;
}

.switch-style>.form-check-input:focus {

    border-color: #5644de2b;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #5644de2b;
}

.switch-style input:focus {
    --bs-form-switch-bg:
        url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%235644de2b%27/></svg>");
}