.form-group.required .form-label:after {
    content: "*";
    color: red;
}

.modal-backdrop {
    z-index: 10000 !important;
}

.modal {
    z-index: 100000 !important;
}

.sign-form {
    font-family: 'Poppins';
    /* margin-left: 31% !important; */
    margin-top: 6% !important;
    width: 90% !important;
}

.sign-logo-style {
    margin: 2%;
    width: 150px;
}

.bg-text-color {
    background-color: #F1F5F9;
}

.bg-text-color::placeholder {
    color: #666F79;
}

.label-font-style {
    font-size: small;
    font-weight: bold;
}

.alert-box {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}



.failure {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    display: none;
    font-size: smaller;
}

.modal-close-btn {
    margin-right: 40% !important;
}

.sign-form .btn {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 14px 25px;
    gap: 10px;
    /* width: 70%; */
    /* height: 48px; */
    color: #FFFF;
    background: #5941EB;
    border: 1px solid #EEEEEE;
    border-radius: 60px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

}

.hidden {
    display: none;
}

.show-ThankU {
    display: block;
}