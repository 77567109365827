.text {
    margin-inline: "20px";
    color: darkslategrey;
    font-weight: 700;
    margin-inline: 12px;
}


a.text:hover {
    border-radius: 5px;
    color: #007bff !important;
    background: gainsboro !important;
}

.searchbar {
    background-color: gainsboro;
    width: 300px;
}

.searchbtn {
    width: 300px;
    margin-inline: 10px;
}

.profile {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 100px;
    background-color: black;
}

.dropdown-style {
    background-color: #5644de !important;
}

.doi .dropdown-item:active {
    background-color: #B9AFF7 !important;
}

.gen-button {
    width: 30%;
    font-weight: bold;
    height: 40px;
    background-color: #5644de !important;
}

.file-cancel:hover {
    cursor: pointer;
}

.topright {
    position: absolute;
    top: 8px;
    right: 16px;
}


.upload-container {
    display: flex;
    justify-content: space-around;
}

.dragdrop-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-style {
    width: 624px;
    height: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #000000;
    margin-bottom: -15px;
}

.input-style {
    width: 392px;
    height: 38px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 16px;
    gap: 10px;
    background: #F1F5F9;
    border-radius: 8px;
    letter-spacing: -0.03em;
    color: #666F79;
    margin-bottom: 40px;
}

.label-style:after {
    content: "*";
    color: red;
}

.interset-style {
    width: 65%;
}

.doi-style {
    border-radius: 20px 0 0 20px !important;
    border: none;
}

.doi-style:active,
.doi-style:focus {
    border: none !important;
    outline: none !important;
}

.doi-select>.dropdown-toggle {
    border-radius: 0 20px 20px 0 !important;
}

.drop-style {
    height: 50vh;
    overflow-y: scroll;
}

.drop-width {
    width: 335px;
    overflow-x: scroll;
}

.upload-width {
    width: 36%;
}