.doc-audios .input-label {
    width: 100% !important;
    height: 100% !important;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0 !important;
    margin-right: 6px;
}

.doc-audios .from-style {
    display: flex;
    align-items: center;
}

.doc-audios .input-box {
    width: 575px !important;
    height: 40px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}