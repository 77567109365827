.error-container {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */

    text-align: center;
    letter-spacing: -0.03em;

}

.btn-reload {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 48px;
    gap: 10px;
    width: 200px;
    height: 48px;
    color: #FFFF;
    background: #5941EB;
    border: 1px solid #EEEEEE;
    border-radius: 60px;
    font-size: 16px;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}