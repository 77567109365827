.search-profile-col {
    border-radius: 8px;
    border: 1px solid #E2E8F0;
    background: #F8F9FA;
    margin-top: 50px;
}

.search-profile-col .profile-pub-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}


.user-search-img {
    width: 5%;
    height: 5%;
    border-radius: 50%;
}

.users-grid-row {
    border: 1px solid #C1C1C1;
    /* background-color: #F0F0F0; */
    display: flex;
    align-items: start;
    border-radius: 20px;
    cursor: pointer;
}

.users-grid-row .font_style {
    font-size: 12px;
    color: #C1C1C1;
}