.font-color {
  color: #999999;
  font-size: 13px;
}

.com-text-style {
  justify-content: left;
  display: flex;
  padding-right: 4%;
}

.user-com-image {
  width: 40px !important;
  height: 40px !important;
  margin-right: 2px;
  flex-shrink: 0;
  object-fit: fill;
  border-radius: 7px;
}

.c-user-details {
  color: #999;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.c-time {
  color: #999;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.comment {
  width: 100% !important;
}

.user-name-text {
  font-size: 14px !important;
  font-weight: 700;
}

.comment-details {
  width: 17%;
}