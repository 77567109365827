div.DraftEditor-root {
    background-color: #F9F6EE;
    height: 500px;
    width: 829px;
    overflow: hidden;
    color: #000000;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    height: 100%;
}

/* .DraftEditor-editorContainer {
    margin: 10%;
} */
.public-DraftEditor-content {
    overflow-y: scroll;
}

.public-DraftEditor-content::-webkit-scrollbar {
    width: 0;
}

.edit-btn {
    position: absolute;
    right: 3px;
    top: 4px;
    color: #5644de;
    background-color: transparent;
    border-color: transparent;
}

.editor-header {
    position: relative;
    z-index: 3;
}