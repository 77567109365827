.option-button {
    float: left;
    margin: 0 5px 0 0;
    width: 100px;
    height: 40px;
    position: relative;
}

.option-button label,
.option-button input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
}

.option-button input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
    width: 100%;
}

.option-button input[type="radio"]:checked+label {
    background: #5644de;
    color: #FFFFFF;
    border-radius: 4px;
}

.option-button label {
    color: #9999;
    border: 1px solid;
    padding: 4px;
    border-color: #9999;
    cursor: pointer;
    z-index: 90;
    line-height: 1.8em;
}

.radio_label:after {
    content: "*";
    color: red;
}

.option-button input[type="radio"]:disabled+label {
    background: #f0eff7;
}

.disable-opt {
    cursor: not-allowed !important;
}