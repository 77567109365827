.tab {
    background: transparent;
    border: none;
    color: #43444e;
    font-size: 16px;
    font-weight: 600;
}

.profile-img-style {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    object-fit: cover;
}

.edit-profile-btn {
    right: -10px;
    bottom: -10px;
    height: 30px;
    padding: 0 !important;
    width: 30px;
}

.tab:hover {
    color: #3548f1;
}

.profile-content {
    min-height: 84vh;
}

.profile-content-p {
    margin-left: 115px;
    width: 90%;
    min-height: 84vh;
}

.btn-viewprofile,
.btn-viewprofile:focus,
.btn-viewprofile:hover,
.btn-viewprofile:active {
    color: #FFFFFF;
    background-color: #5644de !important;
    border-color: #5644de !important;
    font-size: 11.5px;
    width: 80%;
    /* height: 30%; */
}

.edit-font-size {
    font-size: 11.5px;
}

.member-img {
    width: 20%;
    height: 20%;
    border-radius: 50%;
}

@media (min-width:2000px) {
    .videoCard {
        width: 11.5% !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .smaller-grid-card .videoCard {
        width: 13.5% !important;
    }
}

@media (max-width:2500px) {
    .smaller-grid-card .videoCard {
        width: 15.5% !important;
    }
}

@media (max-width:1700px) {
    .smaller-grid-card .videoCard {
        width: 22.5% !important;
    }
}

.change-password {
    color: #5644de !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
}

.form-profile input:focus {
    box-shadow: none !important;
}