.viewer-container {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #E2E8F0;
    background: #F8F9FA;
}


.video-btn {
    font-size: 14px !important;
    font-weight: 500 !important;
}


.stats-font span {
    font-size: 16px;
    color: #999;
}

.download-style {
    text-decoration: none;
    color: #5644de !important;
}