.gen-content {
    width: 100%;
    min-height: 80vh;
    margin-top: 78px;
}


.title_doc_style {
    display: flex;
    font-family: 'Roboto', Arial, sans-serif !important;
    font-size: 22px;
    text-align: center;
    font-weight: 800;
    text-decoration: none;
    color: rgb(86, 68, 222);
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: justify;
    text-align-last: center;
}

.generate-header {
    font-family: 'Roboto', Arial, sans-serif !important;
}

.author-doc-style {
    font-family: inherit !important;
    font-size: 15px;
}

.date-doc-style {
    color: #cecece;
    font-size: 13px;
}

.pub-font-style>span {
    font-family: inherit !important;
    font-size: 15px;
    font-weight: 600;
}

.type-doc-style {
    background-color: #CACED3;
    color: #fff;
    font-weight: 500;
    padding: 3px 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
}

.title_doc_style:hover {
    text-decoration: underline;
}

.sub-badge>.bg-primary {

    background-color: #f8f9fa !important;
}

.author-badge>.bg-primary {
    background-color: #B9AFF7 !important;
    color: black !important;

}

.gen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: start;
    flex-direction: column;
}

.stepprogress-row {
    display: table-row;
}

.stepprogress {
    display: table;
    width: 70%;
    position: relative;
}

.summarize-container .stepprogress,
.g-audio-container .stepprogress,
.c-generate-container .stepprogress {
    width: 100% !important;
}

.summarize-container .stepprogress>.progress,
.g-audio-container .stepprogress>.progress {
    height: 1px !important;
    background-color: #ccc;
    top: 45px;
    bottom: 0;
    position: absolute;
    width: 85%;
    left: 93px;
    z-index: -1000;
}

.stepprogress-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

/* .stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ccc;

} */

.stepprogress>.progress {
    height: 1px !important;
    background-color: #ccc;
    top: 14px;
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: -1000;
}

.auto .stepprogress>.progress,
.c-generate-container .stepprogress>.progress {
    top: 47px !important;
    /* left: 175px; */
    width: 85% !important;
}

.stepprogress>.progress>.progress-bar {
    background-color: #B9AFF7 !important;
}

.video-bar {
    width: 80%;
}

.video-bar>.progress-bar {
    background-color: #5644de !important;
}

.active-step {
    color: #5644de !important;
    background-color: #FFFFFF !important;
    /* background-color: green !important; */
    border: none !important;
}

.finished-step {
    color: #B9AFF7 !important;
    background-color: #FFFFFF !important;
    /* background-color: green !important; */
    border: none !important;
}

.step-border {
    border: 2px solid #cecece;
    border-radius: 10px;
}

.active-border {
    border-color: #5644de !important;
}

.non-active-step {
    color: #cecece !important;
    background-color: #FFFFFF !important;
    border: none !important;

}

.stepper-panel {
    display: flex;
    justify-content: center;
    /* align-items: center;  */
}


.stepprogress-step {
    display: table-cell;
    /* text-align: center; */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .auto .stepprogress-step {
    background-color: #ffffff;
} */

.step-text {
    width: 64px !important;
    height: 50px !important;
    text-align: center !important;
    padding: 6px 0 !important;
    font-size: 12px !important;
    line-height: 1.428571429 !important;
    font-size: 15px !important;
    /* border-radius: 15px !important; */
}

.auto .step-text,
.c-generate-container .step-text {
    height: inherit !important;
    font-size: 11px !important;
    padding: 4px 0 !important;

}

.icon-bg {
    background-color: white;
}

.gen-form-container {
    display: flex;
    justify-content: center;
    padding: 0px;
    /* height: 588px; */
    transform: scale(0.8, 0.8);
    /* margin-top: 5%; */
    flex-direction: column;
    align-items: center
}

/* .gen-page-wrapper {
    display: flex !important;
    flex-direction: row;
    align-content: start;
   
} */

.gen-form-description {
    width: 624px;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.03em;
    margin-bottom: 0;
    color: #666F79;
}

.gen-form-title {
    font-weight: 700;
    size: 36px;
    line-height: 44px;
    width: 624px;
    height: 44px;
}

.gen-form-details {
    /* margin-top: 50px; */
    padding: 0px;
    gap: 48px;
    width: 624px;
    height: 264px;
}

.scrolled-parent {
    height: 100%;
    margin-right: -50px;
    /* Maximum width of scrollbar */
    padding-right: 50px;
    /* Maximum width of scrollbar */
    overflow-y: scroll;
}

/* 
  .formInput {
    display: flex;
    flex-direction: column;
  } */


.gen-button {
    width: 15% !important;
}

/* .gen-button {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px 16px;
    width: 624px;
    height: 45px;
    background: #5644de !important;
    border-radius: 8px;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 24px !important;
    letter-spacing: -0.03em !important;
} */

.gen-form-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
    width: 966px;
}

.gen-btns {
    display: flex;
    flex-direction: column;
    align-content: start;
    justify-content: start;
    align-items: center;
}

.gen-content .error-msg {
    color: red;
    font-weight: 600;
    text-align: end !important;
    width: 50%;
}

.generate-content {
    margin-left: 45px;
    width: 95%;
    min-height: 84vh;
}

.generate-content-p {
    margin-left: 175px;
    width: 84%;
    min-height: 84vh;
}

.generate-content-p div.DraftEditor-root {
    width: 662px !important;
}


.auto .stepprogress>.progress,
.c-generate-container .stepprogress>.progress {
    width: 100%;
}

.auto .stepprogress,
.c-generate-container .stepprogress {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.auto .stepper-panel,
.c-generate-container .stepper-panel {
    justify-content: space-between;
    width: 95% !important;
}

.generate-content-p .summ-list,
.generate-content-p .cont-list,
.generate-content-p .p-summ-list {
    width: 690px !important;
}

.generate-container .loading-spinner {
    margin-top: 7%;
}

.sum-btn {
    color: white !important;
    border-radius: 20px;
    padding: 8px;
    width: auto !important;
    height: auto;
    border: none;
    background-color: #5644de !important;
}

.subscrtibe-color {
    color: #5644de;
}


.hide-bk-icon {
    position: absolute;
    left: 2%;
    top: -17px;
    background-color: #F8F9FA;
    border-top: 1px solid #E2E8F0;
    border-left: 1px solid #E2E8F0;
    border-right: 1px solid #E2E8F0;
    width: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0 0;
    height: 3vh;
}

.tracks-style .size-vid {
    border: 2px solid #E2E8F0;
    border-radius: 15px;
}

.track-details {
    background-color: #E2E8F0;
    border-radius: 0 0 10px 10px;
}

.tracks .fig-container.checked {
    bottom: 92% !important;
}

.search-placeholder {
    color: #CACED3;
}

h4.search-placeholder {
    font-size: 20px;
}

span.search-placeholder {
    font-size: 14px;
}

.audio-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    width: 100%;
    height: 30vh;
}

.track-details svg {
    width: 70% !important;
    height: 70% !important;
}

.audio-item-container svg {
    width: 50%;
    height: 50%;
}

.tracks .fig-item {
    /* width: 20%; */
}

.track-title {
    font-size: 12px;
    font-weight: 600;
    width: 11vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.icon-btn,
.icon-btn:focus,
.icon-btn:active {
    border: none;
    background-color: #FFFFFF;
    outline: none;
    border-radius: 15%;
    box-shadow: 0px 4px 16px 0px #00000040;
}


.search-model .modal-header {
    border: none;
}

.search-modal-title {
    font-size: 18px;
    font-weight: 600;
}

.search-title-container {
    width: 98%;
}

.search-modal .tuby-container {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
}

.search-modal .tuby {
    width: 100% !important;
    height: 100% !important;
    padding-bottom: 0 !important;
    border-radius: 15px !important;
}

.search-modal.show .modal-dialog {
    transform: none;
    display: flex;
    justify-content: center;
    --bs-modal-width: 90%;
}

.search-modal .modal-content {
    width: 100% !important;
}

.search-modal .scroll-content {
    height: 75vh;
}

.search-modal .size-vid {
    width: 100%;
    height: 100%;
}


.search-modal .tuby-controls-right,
.search-modal .tuby-volume-container,
.search-modal .tuby-center-container,
.search-modal .tuby-seek,
.search-modal .tuby-center-container {
    display: none;
}

.search-modal .tuby-controls {
    height: auto;
    border-radius: 15px !important;
}

.search-modal .tuby-controls-left {
    padding: none;
}

.search-modal .tuby-poster {
    /* height: auto !important; */
    /* max-height: 24vh !important; */
    width: 100% !important;
    height: 100% !important;
    border-radius: 15px !important;
    object-fit: cover;
}

.search-modal .tuby video {
    /* max-height: 24vh !important; */
    border-radius: 15px !important;
}

.controls .reg-button {
    font-size: 14px;
}

.search-modal .fig-item {
    height: auto;
    position: relative;
    /* width: 20vw; */
    /* max-height: 30vh; */
}

.search-modal .size-vid.video-checked {
    border: 3px solid #5644de;
    border-radius: 15px;
    z-index: 1;
}

.search_keyword::placeholder {
    color: #CACED3;
    /* background-color: #CACED3; */
}

.search-modal .fig-container.unchecked>svg {
    display: none;
}

.search-modal .fig-container.checked>svg {
    display: flex;
}

.search-modal .fig-container.unchecked {
    padding: 7%;
}

.search-modal .fig-container {
    /* display: flex; */
    position: absolute;
    right: 3px;
    top: 0;
    border-radius: 15px;
}

.music-icon>svg {
    width: 40%;
    height: 40%;

}

.bk.search-modal .fig-container {
    display: flex;
    /* justify-content: flex-end; */
    position: absolute;
    right: 8px;
    top: 3px;
    /* z-index: 10; */
    border-radius: 15px;
    z-index: 2;
}

.trans.search-modal .fig-container {
    display: flex;
    /* justify-content: flex-end; */
    position: absolute;
    right: 17px;
    top: 3px;
    /* z-index: 10; */
    border-radius: 15px;
    z-index: 2;
}

.trans.search-modal .fig-container.unchecked,
.bk.search-modal .fig-container.unchecked {
    display: none;
}

.search-modal .fig-container.checked>svg {
    border-radius: 15px;
}

.search-keyword-icon {
    color: #CACED3;
    position: absolute;
    top: 33%;
    left: 21%;
    padding-left: 4px;
}

.search_keyword {
    width: 59%;
    height: auto;
    padding: 10px 12px 10px 30px;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #CACED3 !important;
    background-color: #FFFFFF !important;
}

.search_keyword:focus,
.search_keyword:active {
    border-radius: 10px;
    border: 1px solid #CACED3 !important;
    background-color: #FFFFFF !important;
    outline: none !important;
}